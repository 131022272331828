<template>
  <div>
    <div class="antd-table-search-wrap">
      <a-row :gutter="12">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" @submit="searchHandleSubmit">
          <a-col :md="10" :sm="24">
            <a-form-item label="知识库名称">
              <a-input v-model="queryParams.wikiName" placeholder="请输入知识库名称" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQueryForm">重置</a-button>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </div>
    <div class="antd-page-content">
      <div class="antd-toolbar">
        <div class="antd-toolbar-left">
          <a-button type="danger" @click="batCollectEvt()">
             批量取消收藏
          </a-button>
        </div>
        <div class="antd-toolbar-right">
          <div class="antd-toolbar-item">
            <a-tooltip>
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover v-model="settingVisible" placement="bottomLeft" trigger="click">
                <a-icon type="setting" />
                <div slot="content" class="antd-draggable-container">
                  <a-checkbox-group v-model="defaultTableList" @change="tableListHandleChange">
                    <div class="antd-draggable-wrapper" v-for="(item,index) in tableListArr" :key="index">
                      <div class="antd-draggable-list-item" :style="{padding:'4px 0'}">
                        <a-checkbox :value="item.dataIndex">
                          {{ item.title }}
                        </a-checkbox>
                      </div>
                    </div>
                  </a-checkbox-group>
                </div>
              </a-popover>
            </a-tooltip>
          </div>
        </div>
      </div>
      <!-- 新建弹窗 -->
      <add-form v-if="addFormVisible" ref="addForm" @refreshDataList="$refs.table.refresh(true)"></add-form>
      <!-- 编辑弹窗 -->
      <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="$refs.table.refresh(true)"></update-form>
      <!-- 目录 -->
      <class-type-form v-if="classTypeFormVisible" ref="classTypeForm" @refreshDataList="$refs.table.refresh(true)"></class-type-form>

      <s-table size="default" ref="table" rowKey="id" :data="loadData" :pageNum="pageNum" :columns="columns" :alert="alert"
         :rowSelection="rowSelection">
          <a-button
          type="link"
          slot="wikiName"
          slot-scope="text, record"
          @click="detailFormEvt(record)"
          >{{ record.wikiName }}</a-button
          >
        <template slot="action" slot-scope="text,record">
          <a href="javascript:void(0)" class="table-operation-action" v-if="record.collect ==null" @click="handleWithdraw(record.id)">收藏</a>
          <a href="javascript:void(0)" class="table-operation-action" v-else @click="handleRelease(record.id)">取消收藏</a>
          <a href="javascript:void(0)" class="table-operation-action" @click="classTpyeFormEvt(record)">目录</a>
        </template>
      </s-table>
    </div>
  </div>
</template>

<script>
import STable from '@/components/Table'
import { getKnowList, collectKnow, delCollectKnow, delKnowList } from "@/httpsAPI/knowledgeBase/index";
import AddForm from './add'
import UpdateForm from './update'
import ClassTypeForm from '../moudels/mlist'

let columns = [
  { title: "知识名称", dataIndex: "wikiName", scopedSlots: { customRender: "wikiName" },isShow: true },
  { title: "创建人", dataIndex: "createBy", align: 'center', isShow: true },
  { title: "创作时间", dataIndex: "createTime", align: 'center', isShow: true },
  { title: "最新更新时间", dataIndex: "modifyTime", align: 'center', isShow: true },
  { title: "操作", dataIndex: "action", scopedSlots: { customRender: 'action' }, isShow: true }
]
export default {
  name: "collection",
  data () {
    return {
      addFormVisible: false,
      updateFormVisible: false,
      classTypeFormVisible:false,
      queryParams: {},
      pageNum: 1,
      loadData: parameter => {
        this.pageNum = parameter.pageNo
        return getKnowList(Object.assign(parameter, this.queryParams, { type: 3})).then(res => {
          console.log(res.data, "getCourseManagerList")
          return res.data
        })
      },
      tableListArr: (() => {
        return columns.filter(item => {
          return item.dataIndex
        })
      })(),
      defaultTableList: (() => {
        let arr = [];
        columns.map(item => {
          if (item.isShow) {
            arr.push(item.dataIndex)
          }
        })
        return arr
      })(),
      currentMenu: [0],
      settingVisible: false,
      form: this.$form.createForm(this, { name: 'searchProject' }),
      selectedRowKeys: [],
      selectedRows: [],
      alert: {
        show: false, clear: () => { this.selectedRowKeys = [] }
      },
      rowSelection: {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  components: {
    AddForm,
    UpdateForm,
    STable,
    ClassTypeForm
  },
  computed: {
    columns () {
      return columns.filter(item => {
        return item.isShow
      })
    }
  },
  created(){
  },
  methods: {
    // 重置搜索form
    resetQueryForm () {
      this.queryParams = {}
      this.$refs.table.refresh(true)
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    statusClickHandle (item) {
      console.log(item, "item");
      console.log(item.key !== item.key)
      if (item.key != item.key) {
        this.getKnowList()
      }
    },
    searchHandleSubmit (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
        }
      });
    },
    tableListHandleChange (checkedVal) {
      columns.forEach(item => {
        if (checkedVal.includes(item.dataIndex)) {
          item.isShow = true
        } else {
          item.isShow = false
        }
      })
      console.log(this.columns);
    },
    // 新建
    createFormEvt (type) {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init(3)
      })
    },
    // 编辑
    updateFormEvt (record) {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(record)
      })
    },
    // 详情
    detailFormEvt (record) {
      this.$router.push({ path: '/knowledge/collection/detail',query:{wikiId:record.id} })
    },
    // 目录 fromType:0我的知识库，1公共知识库，2我的收藏
    classTpyeFormEvt (record) {
      this.classTypeFormVisible = true
      this.$nextTick(() => {
        this.$refs.classTypeForm.init(record, 2)
      })
    },
    //收藏
    handleWithdraw (id) {
      let dataInfo = typeof parseInt(id) === 'number' ? [id] : this.selectedRowKeys
      collectKnow(dataInfo).then(res => {
        console.log(res)
        // 刷新表格
        this.$refs.table.refresh()
        this.$message.success('收藏成功')
      })
    },
    //取消收藏
    handleRelease (id) {
      if (id == -1 && this.selectedRowKeys.length == 0) {
        this.$message.warning('请选择要操作的数据')
        return
      }
      let dataInfo = id > 0 ? [id] : this.selectedRowKeys
      delCollectKnow(dataInfo).then(res => {
        // 刷新表格
        this.$refs.table.refresh()
        this.$message.success('取消收藏成功')
      })
    },
    // 批量收藏
    batCollectEvt () {
      if (this.selectedRowKeys.length == 0) {
        this.$message.warning({
          content: '请选择一条数据进行操作！',
          duration: 1
        })
        return
      }
      const that = this
      this.$confirm({
        title: '系统提示',
        content: '确认取消收藏选中数据么?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          delCollectKnow(that.selectedRowKeys).then(res => {
            if (res.code == 10000) {
              that.$message.success({
                content: '操作成功',
                duration: 1,
                onClose: () => {
                  that.$refs.table.refresh(true)
                }
              })
            } else {
              that.$message.error({
                content: res.msg,
                duration: 1
              })
            }
          })
        }
      })
    },
    // 删除
    deleteEvt (item) {
      const that = this
      delKnowList([item.id]).then(res => {
        if (res.code == 10000) {
          that.$message.success({
            content: '操作成功',
            duration: 1,
            onClose: () => {
              that.$refs.table.refresh(true)
            }
          })
        } else {
          that.$message.error({
            content: res.msg,
            duration: 1
          })
        }
      })
    },
  }
}
</script>

<style scoped>
</style>